import './Reviews.css';
import femaleProfile from './female_profile.png';
import maleProfile from './male_profile.png';

function Reviews() {
    return (
        <div className="reviews-page">
            <div className='reviews-title'>
                Opinie pacjentow
            </div>
            <div className='reviews-description'>
                Poniżej kilka opinii od moich pacjentów zebranych z rożnych serwisów lekarskich.
            </div>
            <div className='reviews-panel'>
                <div className='review'>
                    <img src={femaleProfile} alt="" className='review-image' />
                    <div className='review-text-panel'>
                        <div className='reviewer-name'>
                            Magdalena
                        </div>
                        <div className='review-text'>
                            "Najlepszy dentysta na świecie!!! Chodzę do Pana doktora ja,mąż moi rodzice i znajomi. Naprawde zna się na swojej 'robocie'"
                        </div>
                    </div>
                </div>
                <div className='review'>
                    <img src={femaleProfile} alt="" className='review-image' />
                    <div className='review-text-panel'>
                        <div className='reviewer-name'>
                            Pacjentka AP
                        </div>
                        <div className='review-text'>
                            "Najlepszy dentysta, jakiego znam: profesjonalny, rzetelny, miły, zawsze uśmiechnięty, ma podejście do pacjenta i sprawia że każdy z uśmiechem wraca do jego gabinetu, bo nie straszne mu borowanie!!"
                        </div>
                    </div>
                </div>
                <div className='review'>
                    <img src={maleProfile} alt="" className='review-image' />
                    <div className='review-text-panel'>
                        <div className='reviewer-name'>
                            Tomek Kornecki
                        </div>
                        <div className='review-text'>
                            "Spoko. Usmiech życzliwość profesjonalizm. to jest to."
                        </div>
                    </div>
                </div>
                <div className='review'>
                    <img src={femaleProfile} alt="" className='review-image' />
                    <div className='review-text-panel'>
                        <div className='reviewer-name'>
                            Monika
                        </div>
                        <div className='review-text'>
                            "Tiaaaa.Wszystkie zęby całe.... Zaleczone....nie boli.. bo Super dentysta!!!!!polecam!"
                        </div>
                    </div>
                </div>
                <div className='review-image-source'>
                    <a href="https://pl.freepik.com/darmowe-wektory/plaska-kolekcja-ikon-profilu_28720017.htm#fromView=search&page=1&position=48&uuid=5154f56f-cb05-4035-99af-31cdd812930c&new_detail=true">Obrazy autorstwa freepik</a>
                </div>
            </div>
        </div>
    )
}

export default Reviews;