import './Services.css';

function Services() {
    return (
        <div className="services-page">
            <div className="services-page-title">
                Usługi
            </div>
            <div className="services-content">
                <div className="services-content-column">
                    <ul>
                        <li className="services-main-item">Diagnostyka</li>
                        <ul>
                            <li className="services-subitem">stanu błon śluzowych jamy ustnej i dziąseł</li>
                            <li className="services-subitem">tkanek twardych zęba</li>
                            <li className="services-subitem">stawu skroniowo-żuchwowego</li>
                        </ul>
                        <li className="services-main-item">Profilaktyka</li>
                        <ul>
                            <li className="services-subitem">fluoryzacja</li>
                            <li className="services-subitem">instruktaż higieny jamy ustnej</li>
                            <li className="services-subitem">dobranie szczoteczki i pasty do indywidualnych potrzeb pacjenta</li>
                        </ul>
                        <li className="services-main-item">Leczenie</li>
                        <ul>
                            <li className="services-subitem">leczenie próchnicy</li>
                            <li className="services-subitem">odbudowa tkanek zeba</li>
                            <li className="services-subitem">leczenie kanałowe</li>
                        </ul>
                    </ul>
                </div>
                <div className="services-content-column">
                    <ul>
                        <li className="services-main-item">​Stomatologia estetyczna</li>
                        <ul>
                            <li className="services-subitem">wybielanie</li>
                            <li className="services-subitem">usuwanie kamienia nazębnego</li>
                            <li className="services-subitem">usuwanie przebarwień</li>
                        </ul>
                        <li className="services-main-item">Ortodoncja</li>
                        <ul>
                            <li className="services-subitem">diagnostyka i leczenie wad zgryzu</li>
                            <li className="services-subitem">wyciski</li>
                            <li className="services-subitem">aparaty tradycyjne i kosmetyczne</li>
                        </ul>
                        <li className="services-main-item">Protetyka</li>
                        <ul>
                            <li className="services-subitem">korony i mosty</li>
                            <li className="services-subitem">protezy częściowe i całkowite</li>
                            <li className="services-subitem">protezy szkieletowe</li>
                        </ul>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Services;