import './Contact.css';

function Contact() {
    return (
        <div className="contact-page">
            <div className="contact-title">
                Kontakt
            </div>
            <div className="contact-panel">
                <div className="contact-description">
                    Prywatna Praktyka Stomatologiczna<br />
                    Paweł Barucha
                    <div className="contact-address">
                        Stanisława Wyspiańskiego 1/36<br />
                        25-409 Kielce
                    </div>
                </div>
                <div className='contact-us'>
                    Godziny otwarcia:<br />
                    Poniedziałek-środa: 14-19 <br/> <br/>
                    Umawianie wizyt jedynie telefonicznie:<br />
                    <strong>606 833 234</strong>

                </div>
            </div>
            <div className='contact-map'>
                <iframe title='dojazd' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.0227953150365!2d20.641636776870698!3d50.88628845588673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4718270350daafdd%3A0xe97c2a01efc9f78e!2sStanis%C5%82awa%20Wyspia%C5%84skiego%201%2C%2025-409%20Kielce!5e0!3m2!1spl!2spl!4v1736875924618!5m2!1spl!2spl" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default Contact;