import React from "react";
import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

function NavBar() {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Divider />
            <List>
                <ListItem key={'home'} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={()=> navigate('/')}>
                        <ListItemText primary="Strona główna" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'about'} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={()=> navigate('/o-mnie')}>
                        <ListItemText primary="O mnie" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'services'} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={()=> navigate('/uslugi')}>
                        <ListItemText primary="Usługi" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'reviews'} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={()=> navigate('/opinie')}>
                        <ListItemText primary="Opinie pacjentów" />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'contact'} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={()=> navigate('/kontakt')}>
                        <ListItemText primary="Kontakt" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{ position: { xs: 'absolute', sm: "relative" } }}>
                <Toolbar sx={{ width: '100%' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%', justifyContent: 'center', maxWidth: '1280px', margin: '0px auto' }}>
                        <Button key='home' sx={{ color: '#fff', flex: '1 1 0px;', fontSize: '1rem' }} onClick={() => navigate('/')}>
                            Strona główna
                        </Button>
                        <Button key='about' sx={{ color: '#fff', flex: '1 1 0px;', fontSize: '1rem' }} onClick={() => navigate('/o-mnie')}>
                            O mnie
                        </Button>
                        <Button key='services' sx={{ color: '#fff', flex: '1 1 0px;', fontSize: '1rem' }} onClick={() => navigate('/uslugi')}>
                            Usługi
                        </Button>
                        <Button key='patients' sx={{ color: '#fff', flex: '1 1 0px;', fontSize: '1rem' }} onClick={() => navigate('/opinie')}>
                            Opinie pacjentów
                        </Button>
                        <Button key='contact' sx={{ color: '#fff', flex: '1 1 0px;', fontSize: '1rem' }} onClick={() => navigate('/kontakt')}>
                            Kontakt
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={() => window.document.body}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

export default NavBar;