import './About.css';
import profilePic from './profile_pic.png';

function About() {
    return (
        <div className="about-page">
            <div className='about-page-title'>
                O mnie
            </div>
            <div className='about-page-details-panel'>
                <img src={profilePic} alt="" className='about-page-image' />
                <div className='about-page-details'>
                    <div className='about-page-details-title'>
                        lek. dent. Paweł Barucha
                    </div>
                    <ul>
                        <li className='about-page-list-item'>
                            Absolwent Śląskiego Uniwersytetu Medycznego (1999)
                        </li>
                        <li className='about-page-list-item'>
                            ZOZ Kielce (1999-2001)
                        </li>
                        <li className='about-page-list-item'>
                            AB Dent Nowiny (2000-2004)
                        </li>
                        <li className='about-page-list-item'>
                            MSWiA Kielce (2008-2012)
                        </li>
                        <li className='about-page-list-item'>
                            Prywatna Praktyka Stomatologiczna Paweł Barucha od 2003
                        </li>
                    </ul>
                </div>
            </div>
            <div className='about-page-description'>
                Zajmuję się stomatologią od 20 lat. Do każdego pacjenta podchodzę indywidualnie i dostosowuję leczenie do indywidualnych potrzeb. Korzystam jedynie z profesjonalnych, sprawdzonych materiałów i nie stosuję tanich zamienników. Przychodząc do mnie masz pewność ze otrzymujesz usługi na najwyższym poziomie w przystępnej cenie.
            </div>
        </div>
    )
}

export default About;