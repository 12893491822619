import './Home.css';
import React, { useLayoutEffect, useState } from "react";
import smilingLady1 from './smiling_lady1.jpg';
import smilingLady2 from './smiling_lady2.jpg';
import { Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const images = [smilingLady1, smilingLady2];
const urls = ['https://pl.freepik.com/darmowe-zdjecie/zblizenie-na-podekscytowana-blond-kobiete-usmiechnieta-zadowolona-patrzaca-z-radoscia-i-wdziecznoscia-stojaca-w-koszulce-na-bialym-tle_24916978.htm#fromView=search&page=1&position=21&uuid=32ff8304-2f15-477d-b2c1-dc93c67dd8df&new_detail=true', 'https://pl.freepik.com/darmowe-zdjecie/headshot-portret-szczesliwa-imbirowa-kobieta-z-piegami-usmiecha-sie-biel_8225612.htm#fromView=search&page=1&position=18&uuid=32ff8304-2f15-477d-b2c1-dc93c67dd8df&new_detail=true'];

function Home() {
    const [counter, setCounter] = useState(0);
    const navigate = useNavigate();

    useLayoutEffect(() => {
        var ticker = setInterval(function () {
            setCounter((prevCounter) => (prevCounter + 1) % 2)
        }, 5000)

        return () => {
            clearInterval(ticker);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className='home-panel'>
            <div className='home-image-panel'>
                <Paper elevation={3} sx={{ padding: '5px 3px 10px', borderRadius: '15px', marginTop: '10px' }}>
                    <img src={images[counter]} alt={''} className="home-image" />
                    <div className='home-image-text home-image-text-size'>
                        Powalaj uśmiechem :)
                    </div>
                    <Button variant="contained" sx={{ position: 'absolute', bottom: '20px', right: '0', marginRight: '10px' }} onClick={() => navigate('/kontakt')}>Umów wizytę</Button>
                    <div className='home-image-link home-image-link-size'>
                        <a href={urls[counter]}>Obraz autorstwa cookie_studio na Freepik</a>
                    </div>
                </Paper>
            </div>
            <div className='home-overview-panel'>
                <div className='home-overview-panel-card'>
                    <Paper elevation={3} sx={{ width: { xs: '320px', sm: '410px' }, padding: '5px' }}>
                        <div className='home-overview-card-title'>
                            O mnie
                        </div>
                        <div className='home-overview-card-description'>
                            Zajmuję się stomatologią od 20 lat. Do każdego pacjenta podchodzę indywidualnie i dostosowuję leczenie do indywidualnych potrzeb. Korzystam jedynie z profesjonalnych, sprawdzonych materiałów i nie stosuję tanich zamiennikow. Przychodąc do mnie masz pewność ze otrzymujesz usługi na najwyższym poziomie w przystępnej cenie.
                        </div>
                        <div className='home-overview-card-button'>
                            <Button variant="contained" sx={{ marginRight: '1rem' }} onClick={() => navigate('/o-mnie')}>Więcej</Button>
                        </div>
                    </Paper>
                </div>
                <div className='home-overview-panel-card'>
                    <Paper elevation={3} sx={{ width: { xs: '320px', sm: '410px' }, padding: '5px' }}>
                        <div className='home-overview-card-title'>
                            Usługi
                        </div>
                        <div className='home-overview-card-description'>
                            <ul>
                                <li className='home-overview-card-list-item'>
                                    Diagnostyka
                                </li>
                                <li className='home-overview-card-list-item'>
                                    Profilaktyka
                                </li>
                                <li className='home-overview-card-list-item'>
                                    Leczenie
                                </li>
                                <li className='home-overview-card-list-item'>
                                    Stomatologia estetyczna
                                </li>
                                <li className='home-overview-card-list-item'>
                                    Ortodoncja
                                </li>
                            </ul>
                        </div>
                        <div className='home-overview-card-button'>
                            <Button variant="contained" sx={{ marginRight: '1rem' }} onClick={() => navigate('/uslugi')}>Więcej</Button>
                        </div>
                    </Paper>
                </div>
                <div className='home-overview-panel-card'>
                    <Paper elevation={3} sx={{ width: { xs: '320px', sm: '410px' }, padding: '5px' }}>
                        <div className='home-overview-card-title'>
                            Opinie pacjentów
                        </div>
                        <div className='home-overview-card-description-review'>
                            “Najlepszy dentysta, jakiego znam: profesjonalny, rzetelny, miły, zawsze uśmiechnięty, ma podejście do pacjenta i sprawia że każdy z uśmiechem wraca do jego gabinetu, bo nie straszne mu borowanie!!"
                        </div>
                        <div className='home-overview-card-button'>
                            <Button variant="contained" sx={{ marginRight: '1rem' }} onClick={() => navigate('/opinie')}>Więcej</Button>
                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default Home;